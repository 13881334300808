<template>
  <div>
    <v-row align="center">
      <v-col
        cols="2"
        xs="0"
        sm="0"
        md="1"
      />
      <v-col
        cols="8"
        xs="12"
        sm="12"
        md="10"
      >
        <v-alert
          prominent
          outlined
          type="warning"
          class="errorpage-alert"
          elevation="10"
        >
          <v-row align="center">
            <v-col class="grow">
              <div v-if="$route.query.code === 'networkerror'">
                <h1>Ups – der er opstået en fejl </h1>
                Der har været et problem med serveren. Kode: networkerror. <br> Klik på knappen for at prøve igen. Hvis problemet stadig er der, så kontakt supporten.  
                <v-btn @click="reload">
                  Genindlæs
                </v-btn>
              </div>

              <div v-if="$route.query.code === 'api500'">
                <h1>Ups – der er opstået en fejl </h1>
                Der har været et problem med serveren. Kode: 500. <br> Klik på knappen for at prøve igen. Hvis problemet stadig er der, så kontakt supporten.  
                <!-- <v-btn @click="retry">Prøv igen</v-btn> -->
                <v-btn @click="reload">
                  Genindlæs
                </v-btn>
              </div>

              <div v-if="$route.query.code === 'api404'">
                <h1>Ups – der er opstået en fejl </h1>
                Der har været et problem med serveren. Kode: 404. <br> Klik på knappen for at prøve igen. Hvis problemet stadig er der, så kontakt supporten.  
                <v-btn @click="retry">
                  Prøv igen
                </v-btn>
              </div>

              <div v-if="$route.query.code === 'accessdenied_invalidclient' || $route.query.code === 'accessdenied_invalidresource' || $route.query.code === 'clientautherror_statemismatch'">
                <v-col>
                  <h1>{{ $t('errors.accessDenied') }}</h1>
                  <p>Måske er du logget på med en anden Microsoft konto<span v-if="account && account.username"> ({{ account.username }})</span>, end den der er registeret hos os.</p>
                  <p>Når du bliver spurgt om login, skal du måske prøve at vælge en "anden konto".</p>
                  <p>Klik på knappen for at logge ind igen eller du kan kontakte din administrator eller TPA Support.</p>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    color="primary"
                    @click="login"
                  >
                    Login igen
                  </v-btn>
                </v-col>
              </div>

              <div v-if="$route.query.error === 'UserNotFound'">
                <h1>Anmod om adgang?</h1>
                <p>
                  Din konto <span v-if="account && account.username">({{ account.username }})</span> ser ud til at være relateret til TPA Solutions.<br>
                  Din brugeradgang er sandsynligvis undervejs, men der mangler at blive gjort noget konfiguration af din TPA profil.
                </p>
                <p>Hvis du alligevel tror du er logget ind med en forkert konto, kan du prøve at logge ind igen ved at benytte knappen herunder.</p>
                <p>Alternativt er du velkommen til at kontakte din administrator eller TPA Support. Hvis supporten kan ændre din adgang, kan du vælge at forsøge at prøve at genindlæse systemet igen</p>
                <v-btn @click="retry">
                  Prøv igen
                </v-btn>
                <v-btn
                  class="ml-2"
                  color="primary"
                  @click="login"
                >
                  Login igen
                </v-btn>
              </div>

              <div v-if="$route.query.error === 'UserFoundAccessDenied'">
                <h1>Anmod om adgang?</h1>
                <p>
                  Du har forsøgt at tilgå et modul i TPA Portalen du pt ikke har adgang til.<br>
                  Hvis det er meningen at du skal have denne adgang, bedes du kontakte din administrator eller TPA Support
                </p>
                <p>Hvis du efter endt dialog har fået adgang, kan du prøve at tilgå informationen igen</p>
                <v-btn @click="retry">
                  Prøv igen
                </v-btn>
              </div>

              <div v-else-if="error === 'NoClaims' || error.includes('IDW10204')">
                <h1>{{ $t('errors.accessDenied') }}</h1>
                <p> Det ser ud til at du er logget på med den korrekte Microsoft konto, men ikke har de rettigheder der skal til for at tilgå TPA Portalen. </p>
                <v-btn
                  class="ml-2"
                  color="primary"
                  @click="login"
                >
                  Login igen
                </v-btn>
              </div>

              <div class="mt-5">
                TPA Support kan kontaktes på +45 44 99 99 98 og <a href="mailto:support@tpa-solutions.dk">support@tpa-solutions.dk</a><br>man-tors 8-17, fredag til 8-16
              </div>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col
        cols="2"
        xs="0"
        sm="0"
        md="1"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Error",
  computed: {
    account() {
      return this.$AuthService.accountObj;
    },
    name() {
      return this?.account?.name ?? "Du";
    },
    error() {
      return this.$route.query.error ?? "";
    },
    returnUrl() {
      return this.$route.query.returnUrl ?? '/';
    }
  },
  methods: {
    reload() {
      window.location.href = '/';
    },
    retry() {
      this.$router.push(this.returnUrl);
    },
    login() {
      this.$AuthService.init(true);
    },
  },
};
</script>